import React from "react"
import { useTranslation } from "react-i18next"

import { Layout } from "components/layouts/index-layout"

const IndexPage = ({ children }) => {
  const { t } = useTranslation()

  return <Layout pageName={t("page.main")}>{children}</Layout>
}

export default IndexPage
