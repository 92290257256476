import React, { Component } from "react"
import { Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { NavigationBar, MobileTopBar, MobileBottomBar } from "../navigation"
import SEO from "components/seo"
import { MobileDrawer, useMenuState } from "components/mobile-drawer"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  picture: {
    backgroundColor: "#22b35e",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cover: {
    width: "100%",
    placeItems: "center",
    objectFit: "scale-down",
    zIndex: 1,
  },
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 3,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  footer: {
    padding: "1.0875rem 0px",
    textAlign: "center",
    // color: "#88508F",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  fullWidth: {
    width: "100%",
  },
  container: {
    width: "inherit",
    margin: "unset",
  },
}))

interface Props {
  children?: Component | JSX.Element | Array<JSX.Element>
  pageName: string
}

export const Layout = ({ children, pageName }: Props) => {
  const images = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "gmd-2025-mobile.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      mobile: file(relativePath: { eq: "gmd-2025-bg.jpg" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)

  const classes = useStyles({})
  const { isMenuOpen, closeMenu, toggleMenu } = useMenuState()

  return (
    <div className={classes.root}>
      <SEO title={pageName} />
      <Hidden smDown implementation="css">
        <Grid item xs className={classes.fullWidth}>
          <NavigationBar lightText transparent />
        </Grid>
      </Hidden>
      <Hidden mdUp implementation="css">
        <MobileTopBar lightText />
        <MobileDrawer isMenuOpen={isMenuOpen} onClose={closeMenu} />
        <MobileBottomBar onToggleMenu={toggleMenu} fixed transparent light />
      </Hidden>
      <main className={classes.main}>{children}</main>
      <footer className={classes.footer}>
        © GMD {new Date().getFullYear()}
      </footer>
      <picture className={classes.picture}>
        <source
          className={classes.cover}
          srcSet={images.desktop.childImageSharp.original.src}
          media="(max-width: 900px)"
        />
        <img
          className={classes.cover}
          src={images.mobile.childImageSharp.original.src}
          alt="crowd"
        />
      </picture>
    </div>
  )
}
